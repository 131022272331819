.mb-about-me {
    width: 100vm;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-am-d {
    width: 100vm;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(156, 235, 10); */
}
.mb-am-dt {
    width: 100vm;
    margin-bottom: 10px;
    padding-top: 30px;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-am-dt-n {
    margin-bottom: 40px;
    font-size: 28px;
    color: #b27427;
    font-weight: normal;
}

.mb-am-dt-com {
    margin: 10px 20px;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-am-dt-coms {
    font-size: 12px;
    color: darkgrey;
    /* color: rgb(126, 125, 125); */
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-am-dt-comt {
    font-size: 18px;
    color: #b27427;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-am-dt-add {
    margin: 30px 20px;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-am-dt-adds {
    font-size: 12px;
    color: darkgrey;
    /* color: rgb(126, 125, 125); */
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-am-dt-addt {
    font-size: 16px;
    color: #b27427;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-am-dt-s {
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-am-dt-ss {
    font-size: 14px;
    color: darkgrey;
    padding-top: 5px;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-am-dt-st {
    font-size: 26px;
    color: #07c160;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-am-dt-sb {
    margin-top: 5px;
    margin-left: 10px;
    padding: 4px 8px;
    font-size: 12px;
    color: white;
    border-radius: 105px;
    background-color: red;
    border:0px;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-am-dq {
    padding-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.mb-am-dt-qr {
    width: 170px;
    height: 170px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    border:1px solid #b27427;
    margin-bottom: 10px;
}
.mb-am-dt-qri {
    height:165px;
    width: 165px;
}
.mb-am-dt-qrt {
    text-align: center;
    font-size: 16px;
    color: #b27427;
}
