.app-mb {
  width: 100%;
  text-align: center;
}

input{outline: none;}
.mb-body {
  width: 100%;
  text-align: center;
}

.mb-bo-adv {
  width: 100%;
}

.mb-bo-t {
  height: 50px;
  width: 100%;
  margin:0 auto;
  text-align: center;
  color:white;
  display: flex;
  font-size: 20px;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  background-color: #b27427;
}

.mb-bo-in {
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;

}

.mb-bo-f {
  width: 100%;
  text-align: left;
  display: inline-block;
  flex-direction: column;
    /* border:1px solid #c20523; */
}

.mb-bo-inf {
  margin: 10px 15px;
  padding: 10px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content:center;
  border-radius: 10px;
  border:1px solid #b27427;

}

.mb-bo-fbt {
  height: 50px;
  width: 100%;
  margin:20 auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
}

.mb-bo-ady {
  height: 100%;
  margin: 20px 0;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;

}

.mb-bo-ajj {
  height: 40px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;

}

.mb-jj-d {
  height: 100%;
  width: 100%;
  padding:10px 20px;
  text-align: left;
}

.mb-jj-dp {
  width: 100%;
  font-size: 14px;
  line-height: 1.8;
  text-indent:2em;
}

.mb-cj-d {
  height: auto;
  width: 100%;
  padding:10px 20px;
  text-align: left;
}

.mb-jj-dpt {
  width: 100%;
  margin:0 auto;
  font-size: 16px;
  font-weight: 600;
}

.mb-jj-dpp {
  width: 100%;
  margin:0 auto;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.mb-bo-b {
  width: 100%;
  /* background-color: #c20523; */
}

.mb-m-middle {
  /* height: 500px; */
  margin:0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #c20523; */
}
.mb-m-mid-t {
  height: 80px;
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border:1px solid white; */
}
.mb-m-mid-tt {
  padding: 6px 15px;
  font-size: 26px;
  /* font-weight: 100; */
  /* font-family:'Courier New', Courier, monospace; */
  /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  /* font-family:Georgia, 'Times New Roman', Times, serif; */
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #c20523;
  text-align: center;
}
.mb-mid-middle {
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center
}
.mb-m-m-l-t {
  width: 100%;
  border-top:1px solid white;
  border-bottom:1px solid white;
}

.ed-mb {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction:column;
  align-items:center;
  align-content:center;
  justify-content:center;
  margin-top: 30px;
  padding-bottom: 20px;
}

.ed-mb-info {
  font-size: 14px;
  text-align: center;
  color: #000;
}

.ed-mb-infos {
  font-size: 12px;
  text-align: center;
  color: #000;
}


.qr-mb {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  align-items:center;
  align-content:center;
  justify-content:center;
}

.qr-mb-image {
  height:120px;
  width: 120px;
}

.qr-mb-image-img {
  height:120px;
  width: 120px;
  border:1px solid #c20523;
}

.qr-mb-image-info {
  height:30px;
  width: 260px;
  padding-top: 10px;
  color:#b27427;
  /* border:1px solid #c20523; */
}

.border-s {
  border:1px solid #c20523;
}





