.pc-follow {
    width: 100vm;
    height: 100vm;
    text-align: center;
    display: flex;
    background-color: #f2f4f6;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.pc-fo-d {
    top: 20px;
    bottom: 20px;
    width: 700px;
    min-height: 700px;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    box-sizing: border-box;
    background: white;
    /* border-top: 6px solid #c20523; */
    border-top: 6px solid #b27427;
    border-radius: 15px;
    box-shadow: 0 6px 10px rgba(0,0,0,0.1);
}
.pc-fo-dt {
    width: 700px;
    margin-top: 50px;
    margin-bottom: 30px;
}

.pc-fo-dt-n {
    
    /* margin-bottom: 30px; */
    font-size: 36px;
    color: #b27427;
    font-weight: normal;
}

.pc-fo-dt-s {
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-ss {
    font-size: 20px;
    color: darkgrey;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-st {
    font-size: 30px;
    color: #07c160;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-sb {
    margin-top: 10px;
    margin-left: 20px;
    padding: 3px 6px;
    font-size: 14px;
    color: white;
    background-color: red;
    border:0px;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-o {
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-os {
    font-size: 18px;
    color: darkgrey;;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-ot {
    font-size: 20px;
    color: #b27427;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-ob {
    margin-top: 5px;
    margin-left: 10px;
    padding: 4px 8px;
    font-size: 10px;
    color: white;
    border-radius: 50%;
    background-color: red;
    border:0px;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-b {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-bt {
    width: 300px;
    height: 46px;
    line-height: 46px;
    display: block;
    font-size: 18px;
    font-weight: 650;
    color: #fff;
    background-color: #07c160;
    border-radius: 23px;
    text-align: center;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    text-shadow:none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    border: 0px;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-m {
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-ms {
    font-size: 20px;
    color: darkgrey;;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dt-mt {
    font-size: 32px;
    color: red;
    /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    /* font-family:'Times New Roman', Times, serif; */
    /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-family:Georgia, 'Times New Roman', Times, serif;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-fo-dq {
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.pc-fo-dt-qr {
    width: 170px;
    height: 170px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    border:1px solid #b27427;
}
.pc-fo-dt-qri {
    height:165px;
    width: 165px;
}
.pc-fo-dt-qrt {
    margin-top: 30px;
    text-align: center;
    font-size: 24px;
    color: #b27427;
}

.pc-fo-dt-ts {
    margin-top: 30px;
    width: 560px;
    text-align: center;
    font-size: 18px;
    color: red;
}
.mak-d {
    margin:30px;
}
.mak-d-t {
    height: 50px;
    color: #52c41a;
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.mak-d-ti {
    margin: 8px 6px 0px 6px;
}
.mak-d-a {
    padding: 10px;
    font-size: 16px;
    color: red;
    text-align: left;
}
.mak-d-c {
    margin: 20px 0px 0px 0px;
    padding: 30px 30px 20px 30px;
    text-align: center;
}
.mak-d-b {
    margin-top: 20px;
    padding: 10px;
    font-size: 20px;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:space-between;
}
