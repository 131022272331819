
.App-pc {
    width: 100vm;
    text-align: center;
}
  
  .pc-middle,.pc-end {
    width: 1024px;
    margin:0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
  }
  .pc-body {
    width: 100%;
    text-align: center;
  }
  .pc-bo-c {
    width: 1024;
    margin:0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pc-bo-b {
    width: 100%;
    /* background-color: #c20523; */
  }
  .pc-bo-i {
    width: 1024;
    margin:0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pc-bo-f {
    width: 620;
    margin:0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
      /* border:1px solid #c20523; */
  }

  .pc-p-d {
    width: 700px;
    /* border:1px solid red; */

}

.pc-p-dt {
    width: 700px;
    height: 220px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    /* border:1px solid red; */
}

.pc-p-dl {
    width: 400px;
    /* border:1px solid red; */
}
.pc-p-dr {
    width: 140px;
    height: 120px;
    /* margin-right: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    border:1px solid red;
    /* border:1px solid rgb(165, 165, 165); */
}

.pc-p-dls {
    width: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:left;
    margin-bottom: 8px;
    /* border:1px solid red; */
}

.pc-p-dlsl {
    width: 150px;
    color: gray;
    text-align: right;
    font-size: 16px;
    /* border:1px solid rgb(10, 17, 235); */
}

.pc-p-dlsr {
    width: 250px;
    text-align: left;
    font-size: 16px;
    /* border:1px solid rgb(10, 17, 235); */
}

.pc-p-dt-pl {
    font-size: 18px;
    color:darkgrey;
    text-decoration:line-through;
}
.pc-p-dt-pll {
    font-size: 16px;
    color: red;
}

.pc-p-dt-plr {
    font-size: 40px;
    font-weight: 600;
    color: red;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.pc-p-dt-pr {
    height: 80px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.pc-p-dm {
    width: 700px;
    height: 70px;
    text-align: center;
    display: flex;
    flex-direction:column;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}

.pc-p-dm-t {
    color: red;
    font-size: 24px;
    font-weight: 600;
}

.pc-p-de {
    width: 700px;
    padding:30px 0px 0px 0px;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-p-eq {
    width: 210px;
    height: 210px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    background-color: #07c160;
    /* border:1px solid rgb(10, 17, 235); */
}

.pc-p-i-img {
    height:180px;
    width: 180px;
}

.pc-p-et {
    margin-top: 20px;
    text-align: center;
    font-size: 24px;
    /* color:red; */
    color: #07c160;
    /* border:1px solid rgb(10, 17, 235); */
}



