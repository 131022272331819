.mb-name-list {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}

.mb-nl-d {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:space-between;
}

.mb-nl-nd {
    width: 165px;
    margin: 10px 6px;
    padding: 5px 1px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    border:1px solid #b27427;
}

.mb-nld-df {
    width: 110px;
    padding-bottom: 4px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
}

.mb-nld-d {
    width: 110px;
    padding-bottom: 4px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
}

.mb-nld-dt {
    margin: 0 1px;
    text-align: center;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content:center;
    color:#b27427;
}

.mb-nld-dtp {
    height: 10px;
    font-size: 10px;
}

.mb-nld-dtw {
    height: 26px;
    font-size: 22px;
}

.mb-nld-dtb {
    padding-top: 2px;
    height: 16px;
    font-size: 10px;
}

.mb-nld-dtc {
    height: 40px;
    width: 40px;
    margin-left: 5px;
    padding: 3 6px;
    text-align: center;
    color:#b3b3b3;
    font-size: 14px;
    padding-left: 5px;
    border-left:1px solid #b27427;
}