.pc-about-me {
    width: 100vm;
    height: 100vm;
    text-align: center;
    display: flex;
    background-color: #f2f4f6;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.pc-am-d {
    top: 20px;
    bottom: 20px;
    width: 700px;
    min-height: 700px;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    box-sizing: border-box;
    background: white;
    /* border-top: 6px solid #c20523; */
    border-top: 6px solid #b27427;
    border-radius: 15px;
    box-shadow: 0 6px 10px rgba(0,0,0,0.1);
}
.pc-am-dt {
    width: 700px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.pc-am-dt-n {
    margin-bottom: 50px;
    font-size: 36px;
    color: #b27427;
    font-weight: normal;
}

.pc-am-dt-s {
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-am-dt-g {
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-am-dt-ss {
    font-size: 18px;
    color: darkgrey;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-am-dt-st {
    font-size: 24px;
    color: #b27427;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-am-dt-gt {
    font-size: 30px;
    font-weight: 600;
    color: #52c41a;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-am-dq {
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.pc-am-dt-qrt {
    margin-top: 10px;
    text-align: center;
    font-size: 22px;
    color: #b27427;
    margin-bottom: 20px;
}
.pc-am-dt-qr {
    width: 170px;
    height: 170px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    border:1px solid #b27427;
}
.pc-am-dt-qri {
    height:165px;
    width: 165px;
}

.pc-am-dt-m {
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    margin-bottom: 10px;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-am-dt-ms {
    font-size: 20px;
    color: darkgrey;;
    /* border:1px solid rgb(10, 17, 235); */
}
.pc-am-dt-mt {
    font-size: 32px;
    color: #b27427;
    /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    /* font-family:'Times New Roman', Times, serif; */
    /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-family:Georgia, 'Times New Roman', Times, serif;
    /* border:1px solid rgb(10, 17, 235); */
}
