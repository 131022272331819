.mb-follow {
    width: 100vm;
    /* height: 110vh; */
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-d {
    width: 100vm;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(156, 235, 10); */
}
.mb-fo-dt {
    width: 100vm;
    margin-bottom: 30px;
    padding-top: 80px;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-fo-dt-n {
    margin-bottom: 10px;
    font-size: 28px;
    color: #b27427;
    font-weight: normal;
}

.mb-fo-dt-s {
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-ss {
    font-size: 14px;
    color: darkgrey;
    padding-top: 5px;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-st {
    font-size: 26px;
    color: #07c160;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-sb {
    margin-top: 5px;
    margin-left: 10px;
    padding: 4px 8px;
    font-size: 12px;
    color: white;
    border-radius: 105px;
    background-color: red;
    border:0px;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-o {
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-os {
    font-size: 16px;
    color: darkgrey;
    /* color: rgb(126, 125, 125); */
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-ot {
    font-size: 14px;
    color: #b27427;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-m {
    margin: 30px 10px 10px 10px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-ms {
    font-size: 16px;
    color: rgb(61, 61, 61);
    padding-top: 5px;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-mt {
    font-size: 28px;
    color: red;
    /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    /* font-family:'Times New Roman', Times, serif; */
    /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-family:Georgia, 'Times New Roman', Times, serif;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-c {
    margin: 20px 20px 10px 20px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-ct {
    width: 200px;
    height: 36px;
    line-height: 46px;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: red;
    border-radius: 23px;
    text-align: center;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    text-shadow:none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    border: 0px;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-b {
    margin: 40px 20px 10px 20px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-bt {
    width: 100%;
    height: 46px;
    line-height: 46px;
    display: block;
    font-size: 18px;
    font-weight: 650;
    color: #fff;
    background-color: #07c160;
    border-radius: 23px;
    text-align: center;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    text-shadow:none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    border: 0px;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dq {
    padding-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.mb-fo-dt-qr {
    width: 170px;
    height: 170px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    border:1px solid #b27427;
    margin-bottom: 20px;
}
.mb-fo-dt-qri {
    height:165px;
    width: 165px;
}
.mb-fo-dt-qrt {
    text-align: center;
    font-size: 20px;
    color: #b27427;
}

.mb-fo-dt-qrm {
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-qrms {
    font-size: 16px;
    /* color: rgb(61, 61, 61); */
    color: #07c160;
    padding-top: 5px;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-fo-dt-qrmt {
    font-size: 28px;
    color: #07c160;
    /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    /* font-family:'Times New Roman', Times, serif; */
    /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-family:Georgia, 'Times New Roman', Times, serif;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-fo-dt-ts {
    margin: 10px 15px 10px 15px;
    text-align: center;
    font-size: 16px;
    color: red;
}
.mak-d {
    margin:1px;
}
.mak-d-t {
    height: 40px;
    color: #07c160;
    font-size: 26px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    
}
.mak-d-ti {
    margin: 4px 6px 0px 6px;
}
.mak-d-a {
    padding: 10px;
    font-size: 14px;
    color: red;
    text-align: left;
}
.mak-d-c {
    padding: 10px;
    text-align: center;
}
.mak-d-b {
    margin-top: 1px;
    padding: 10px;
    font-size: 20px;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:space-between;
}

.mak-s {
    margin:1px;
}
.mak-s-t {
    height: 40px;
    color: #07c160;
    font-size: 26px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    
}
.mak-s-ti {
    margin: 4px 6px 0px 6px;
}
.mak-s-a {
    padding: 10px;
    font-size: 14px;
    color: red;
    text-align: left;
}
.mak-s-c {
    padding: 10px;
    text-align: center;
}
.mak-s-b {
    margin-top: 1px;
    padding: 10px;
    font-size: 20px;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:space-between;
}


