.mb-result {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.mb-r-d {
    width: 100%;
    text-align: center;
    /* display: flex; */
    flex-direction:row;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* background-color: #b27427; */
}
.mb-r-t {
    width: 100%;
    margin:0 auto;
    text-align: center;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    background-color: #b27427;
}

.mb-r-tt {
    height: 50px;
    width: 100%;
    margin:0 auto;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    font-size: 18px;
}
.mb-r-tb {
    height: 40px;
    width: 100%;
    margin:0 auto;
    text-align: center;
    color:white;
    display: flex;
    font-size: 18px;
    flex-direction: row;
    align-items: center;
    justify-content:center;
}

.mb-r-tbr {
    width: 100%;
    margin:0 auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
}

.mb-p-tbra {
    width: 50%;
    margin:0 auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
}

.mb-r-tab {
    width: 100%;
    display: flex;
    font-size: 18px;
    flex-direction: row;
    align-items: center;
    justify-content:center;
}



.mb-p-dt {
    width: 100%;
    margin: 10px 15px;
    padding: 10px;
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:center;
    border-radius: 10px;
    border:1px solid #b27427;
}

.mb-p-dls {
    /* width: 350px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:left;
    margin-bottom: 8px;
    /* border:1px solid red; */
}

.mb-p-dlsl {
    width: 95px;
    color: gray;
    text-align: right;
    font-size: 16px;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-p-dlsr {
    width: 200px;
    text-align: left;
    font-size: 16px;
    color: #b27427;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-p-pir {
    width: 100%;
    margin:0 auto;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mb-p-dt-pl {
    margin-top: 5px;
    font-size: 14px;
    color:darkgrey;
    text-decoration:line-through;
}
.mb-p-dt-pr {
    height: 40px;
    /* border:1px solid rgb(10, 17, 235); */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.mb-p-dt-pll {
    font-size: 18px;
    color: red;
}

.mb-p-dt-plr {
    font-size: 30px;
    font-weight: 600;
    color: red;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.mb-p-dt-pa {
    margin-top: 10px;
    font-size: 12px;
    color:red;
}

.mb-p-db {
    width: 100%;
    margin:10px;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    color:red;
}

.mb-p-de {
    width: 100%;
    padding:30px;
    text-align: center;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* border:1px solid rgb(10, 17, 235); */
}
.mb-p-eq {
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: block;
    font-size: 22px;
    color: #fff;
    background-color: #07c160;
    border-radius: 25px;
    text-align: center;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    text-shadow:none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    border: 0px;
}
.mak-s {
    margin:1px;
}
.mak-r-t {
    height: 40px;
    color: #07c160;
    font-size: 26px;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    
}
.mak-r-ti {
    margin: 4px 6px 0px 6px;
}
.mak-r-a {
    padding: 10px;
    font-size: 14px;
    color: red;
    text-align: left;
}
.mak-r-c {
    padding: 10px;
    text-align: center;
}
.mak-r-b {
    margin-top: 1px;
    padding: 10px;
    font-size: 20px;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}









