.App-pc {
  width: 100vm;
  text-align: center;
}

.pc-middle,.pc-end {
  width: 1024px;
  margin:0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}
.pc-body {
  width: 100%;
  text-align: center;
}
.pc-bo-c {
  width: 1024;
  margin:0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pc-bo-b {
  width: 100%;
  /* background-color: #c20523; */
}
.pc-bo-i {
  width: 1024;
  margin:0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pc-bo-f {
  width: 620;
  margin:0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
    /* border:1px solid #c20523; */
}

.ant-form-item-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.pc-jj-d {
  height: 160px;
  width: 1024px;
  margin:0 auto;
  text-align: left;
}

.pc-jj-dp {
  width: 900px;
  margin:0 auto;
  font-size: 16px;
  line-height: 2;
  text-indent: 2em;
}

.pc-cj-d {
  height: auto;
  width: 1024px;
  margin:0 auto;
  text-align: left;
}

.pc-jj-dpt {
  width: 900px;
  margin:0 auto;
  font-size: 16px;
  font-weight: 600;
}

.pc-jj-dpp {
  width: 900px;
  margin:0 auto;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.pc-qr{
  margin-top: 50px;
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.pc-qr-q {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  align-items:center;
  align-content:center;
  justify-content:center;
}

.pc-qr-image {
  height:160px;
  width: 160px;
}

.pc-qr-image-img {
  height:160px;
  width: 160px;
  border:1px solid #c20523;
}

.pc-qr-image-info {
  height:30px;
  width: 260px;
  padding-top: 10px;
  color:#b27427;
  /* border:1px solid #c20523; */
}

.ed-pc {
  width: 1024px;
  height: 80px;
 padding-top: 20px;
  text-align: center;
  display: flex;
  flex-direction:column;
  /* flex-wrap: wrap; */
  align-items:center;
  align-content:center;
  justify-content:center;
    /* border:1px solid #c20523; */
}

.ed-pc-info {
  height: 25px;
  font-size: 14px;
  text-align: center;
  color: black;
}
