.pc-name-detail {
    width: 100%;
    height: 100vm;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    background-color: #f2f4f6;
}
.pc-nd-d {
    width: 700px;
    height: auto;
    margin: 0 auto;
    text-align: center;
    display: flex;
    text-align: center;
    flex-direction:column;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
    box-sizing: border-box;
    background: white;
    border-top: 6px solid #b27427;
    border-radius: 15px;
    box-shadow: 0 6px 10px rgba(0,0,0,0.1);
}

.mb-nd-t {
    width: 100%;
    margin:0 auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
    color:white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    background-color: #b27427;
}
.mb-nd-tl {
    width: 50px;
}

.mb-nd-tt {
    height: 50px;
    width: 100%;
    margin:0 auto;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    font-size: 18px;
}
.mb-nd-tb {
    height: 40px;
    width: 100%;
    margin:0 auto;
    text-align: center;
    color:white;
    display: flex;
    font-size: 18px;
    flex-direction: row;
    align-items: center;
    justify-content:center;
}

.mb-na-db {
    width: 100%;
    margin: 10px 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:center;
    border-radius: 10px;
    border:1px solid #b27427;
}

.mb-na-db-s {
    width: 100%;
    margin: 0 auto;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content:center;
}

.mb-na-db-sa {
    font-size: 14px;
    color: #b27427;
}

.mb-na-db-sb {
    font-size: 48px;
    color: red;
}


.mb-nd-db-t {
    width: 100%;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 700;
    color: #b27427;
}

.mb-nd-db-tt {
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    color: #b27427;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
}
.mb-nd-db-tt table{
    border:1px solid #b27427;
}

.mb-nd-db-tt tr{
    height: 35px;
}

.mb-nd-db-tt td{
    padding: 0 5px;

}

.mb-nd-db-tt-td{
    font-size: 12px;
    background-color: bisque;
}

.mb-nd-db-tt-tdx{
    margin-top: 20px;
}

.mb-nd-db-tt-tdr{
    padding: 0 15px;
}

.mb-nd-db-tr {
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
    color: rgb(174, 174, 174);
    display: flex;
    flex-direction: column;
    align-items:left;
    justify-content:left;
    padding: 10px;
    /* border:1px solid #b27427; */
}

.mb-nd-db-trd {
    width: 100%;
    text-align: left;
    padding: 1px 5px;
}

.mb-nd-db-trt {
    width: 100%;
    color: #b27427;;
    text-align: left;
    padding: 1px 5px;
}

.mb-nd-w {
    width: 100%;
    margin:0 auto;
    text-align: center;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    background-color: #b27427;
}

.mb-nd-wt {
    height: 36px;
    width: 100%;
    margin:0 auto;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    font-size: 16px;
}

.mb-nd-wg {
    width: 100%;
    margin:0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}

.mb-nd-wg-t {
    width: 100%;
    margin:0 auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}

.mb-nd-wg-tt {
    width: 100%;
    margin:0 auto;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #b27427;
    justify-content:center;
}
.mb-nd-wg-tn {
    width: 100%;
    margin:0 auto;
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    font-size: 14px;
    justify-content:center;
}
.mb-nd-wg-tndt {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin-bottom: 6px;
    color: #b27427;
}

.mb-nd-wg-tnd {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin-bottom: 5px;
    /* justify-content:left; */
    /* border:1px solid #b27427; */
}

.mb-nd-wg-tndl {
    width: 56px;
    display: inline;
    color: #b27427;
    /* border:1px solid #b27427; */
}
.mb-nd-wg-tndr {
    width: 100%;
    display: inline;
    margin-left: 5px;
    /* border:1px solid #b27427; */
}

.mb-nd-wg-tndjg {
    color: red;
}


.mb-nd-wg-tndd {
    width: 100%;
    display: inline;
    margin-left: 5px;
    font-size: 12px;
    color: darkgrey;
    text-align: left;
    /* border:1px solid #b27427; */
}

.mb-nd-tipss {
    width: 100%;
    margin-top: 30px;
    display: inline;
    margin-left: 5px;
    font-size: 16px;
    color: red;
    
    font-weight: 700;
    text-align: center;
    /* border:1px solid #b27427; */
}
