.mb-search {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.mb-d-d {
    width: 100%;
    text-align: center;
    /* display: flex; */
    flex-direction:row;
    align-items:center;
    align-content:center;
    justify-content:center;
    /* background-color: #b27427; */
}
.mb-d-t {
    width: 100%;
    margin:0 auto;
    text-align: center;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    background-color: #b27427;
}

.mb-d-tt {
    height: 50px;
    width: 100%;
    margin:0 auto;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    font-size: 18px;
}

.mb-d-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}

.mb-dli-st {
    margin: 10px 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    border-radius: 10px;
    border:1px solid #b27427;
}

.mb-dli-dls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:left;
    font-size: 14px;
    margin-bottom: 2px;
}

.mb-dli-dlt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    font-size: 14px;
    margin-bottom: 2px;
}

.mb-dli-dltl {
    width: 95px;
    color: gray;
    text-align: right;
    /*  */
}

.mb-dli-dlbt {
    height: 30px;
    width: 50px;
    margin-top: 5px;
    padding: 5px 4px;
    font-size: 14px;
    width: 95px;
    color: white;
    background-color: #b27427;
    border-radius: 15px;
    /*  */
}

.mb-dli-dltr {
    width: 105px;
    text-align: left;
    color: #b27427;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-dli-dlsl {
    width: 95px;
    color: gray;
    text-align: right;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-dli-dlsr {
    width: 200px;
    text-align: left;
    color: #b27427;
    /* border:1px solid rgb(10, 17, 235); */
}









