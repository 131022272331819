.mb-name-analysis {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}
.mb-na-d {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
    justify-content:center;
}

.mb-na-dt {
    width: 100%;
    margin: 10px 15px;
    padding: 8px;
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:center;
    border-radius: 10px;
    border:1px solid #b27427;
}

.mb-na-dls {
    /* width: 350px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:left;
    margin-bottom: 8px;
    /* border:1px solid red; */
}

.mb-na-dlsl {
    width: 95px;
    color: gray;
    text-align: right;
    font-size: 16px;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-na-dlsr {
    width: 200px;
    text-align: left;
    font-size: 16px;
    color: #b27427;
    /* border:1px solid rgb(10, 17, 235); */
}

.mb-na-db {
    width: 100%;
    margin: 10px 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:center;
    border-radius: 10px;
    border:1px solid #b27427;
}

.mb-na-db-t {
    width: 100%;
    font-size: 16px;
    color: #b27427;
}

.mb-na-db-tx {
    width: 100%;
    font-size: 14px;
    color: #b27427;
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    text-align: left;
    flex-direction: column;
    /* align-items:center; */
    justify-content:left;
}

.mb-na-db-txd {
    width: 100%;
    margin: 2px;
}

.mb-na-db-tt {
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    color: #b27427;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
}
.mb-na-db-tt table{
    border:1px solid #b27427;
}

.mb-na-db-tt tr{
    height: 35px;
}

.mb-na-db-tt td{
    padding: 0 5px;

}

.mb-na-db-tt-td{
    background-color: bisque;
}

.mb-na-db-tt-tdx{
    margin-top: 20px;
}

.mb-na-db-tt-tdr{
    padding: 0 15px;
}

.mb-na-db-tb {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 14px;
    color: #b27427;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
}

.mb-na-db-tbb {
    width: 190px;
    height: 40px;
    padding: 5px 10px;
    font-size: 18px;
    color: white;
    background-color: #b27427;
    border-radius: 25px;
    border:1px solid #b27427;
}
